const CODES = {
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  VALIDATION_ERR: 'VALIDATION_ERR',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  BAD_REQUEST: 'BAD_REQUEST',
  FORBIDDEN: 'FORBIDDEN',
  UNAUTHORIZED: 'UNAUTHORIZED',
  NOT_FOUND: 'NOT_FOUND',
  CONFLICT: 'CONFLICT',
  INVALID_REQUEST: 'INVALID_REQUEST',
  INVALID: 'INVALID',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  ERR_UNESCAPED_CHARACTERS: 'ERR_UNESCAPED_CHARACTERS',
  KEY_CONSTRAINT_VIOLATION: 'KEY_CONSTRAINT_VIOLATION',
  SQL_QUERY_TIMEOUT: 'SQL_QUERY_TIMEOUT',

  // Registration
  UNDER_THIRTEEN: 'UNDER_THIRTEEN',
  USER_DISABLED: 'USER_DISABLED',

  // User
  INVALID_ACCOUNT_VERIFICATION_TOKEN: 'INVALID_ACCOUNT_VERIFICATION_TOKEN',
  USER_NOT_IN_INSTITUTION: 'USER_NOT_IN_INSTITUTION',
  STUDENT_ALREADY_IN_COURSE: 'STUDENT_ALREADY_IN_COURSE',

  // Course
  START_DATE_AFTER_END_DATE: 'START_DATE_AFTER_END_DATE',
  USER_NOT_OWNER: 'USER_NOT_OWNER',
  // Don't know why paths has two error codes USER_NOT_OWNER and USER_NOT_AUTHORIZED
  USER_NOT_AUTHORIZED: 'USER_NOT_AUTHORIZED',
  CREATE_COURSE_WITHOUT_ASSIGN: 'CREATE_COURSE_WITHOUT_ASSIGN',
  COURSE_NOT_FOUND: 'COURSE_NOT_FOUND',
  SECTION_NOT_FOUND: 'SECTION_NOT_FOUND',

  // Assessment Builder
  INVALID_NEW_MAX_NUM_ATTEMPTS: 'INVALID_NEW_MAX_NUM_ATTEMPTS',

  // Course tokens
  SHARE_COURSE_TOKEN_INVALID: 'SHARE_COURSE_TOKEN_INVALID',
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',

  // Copy tokens
  COPY_TOKEN_NOT_FOUND: 'COPY_TOKEN_NOT_FOUND',

  // Sequence
  CANNOT_DISABLE_TARGET_MODULE: 'CANNOT_DISABLE_TARGET_MODULE',
  SEQUENCE_NOT_FOUND: 'SEQUENCE_NOT_FOUND',

  // Learn Flow
  ASSIGNMENT_HAS_NO_HISTORY: 'ASSIGNMENT_HAS_NO_HISTORY',
  NO_INSTRUCTIONAL_CONTENT_FOUND: 'NO_INSTRUCTIONAL_CONTENT_FOUND',
  ANSWER_DOESNT_MATCH_CONTENT: 'ANSWER_DOESNT_MATCH_CONTENT',
  STUDENT_NOT_IN_SECTION: 'STUDENT_NOT_IN_SECTION',
  GOAL_ALREADY_COMPLETE: 'GOAL_ALREADY_COMPLETE',
  INSTRUCTIONAL_CONTENT_NOT_FOUND: 'INSTRUCTIONAL_CONTENT_NOT_FOUND',

  // Quiz
  QUIZ_PASSED_DUE_DATE: 'QUIZ_PASSED_DUE_DATE',
  TIMER_ALREADY_EXPIRED: 'TIMER_ALREADY_EXPIRED',
  TIMER_NOT_YET_EXPIRED: 'TIMER_NOT_YET_EXPIRED',
  QUIZ_NOT_STARTED: 'QUIZ_NOT_STARTED',
  QUIZ_ALREADY_COMPLETED: 'QUIZ_ALREADY_COMPLETED',
  QUIZ_AVAILABLE_IN_PRINT: 'QUIZ_AVAILABLE_IN_PRINT',
  QUIZ_ATTEMPT_ALREADY_COMPLETED: 'QUIZ_ATTEMPT_ALREADY_COMPLETED',

  // Knerd Tools
  MODULE_NOT_FOUND: 'MODULE_NOT_FOUND',
  COURSEPACK_DOES_NOT_EXIST: 'COURSEPACK_DOES_NOT_EXIST',

  // Institution
  INSTITUTION_ALREADY_EXISTS: 'INSTITUTION_ALREADY_EXISTS',

  // Analytics
  ANALYTICS_NOT_AVAILABLE: 'ANALYTICS_NOT_AVAILABLE',

  // Payments
  ACCESS_CODE_INVALID: 'ACCESS_CODE_INVALID',
  ACCESS_CODE_NOT_APPLICABLE: 'ACCESS_CODE_NOT_APPLICABLE',
  ACCESS_CODE_ALREADY_USED: 'ACCESS_CODE_ALREADY_USED',
  FREE_TRIAL_ALREADY_STARTED: 'FREE_TRIAL_ALREADY_STARTED',
  SUBSCRIPTION_PLAN_NOT_FOUND: 'SUBSCRIPTION_PLAN_NOT_FOUND',
  ALREADY_HAS_ACTIVE_SUBSCRIPTION: 'ALREADY_HAS_ACTIVE_SUBSCRIPTION',
  INVALID_BILLING_ADDRESS: 'INVALID_BILLING_ADDRESS',

  // LDB
  LDB_LOGIN_FAILED: 'LDB_LOGIN_FAILED',
}

export default CODES
