import * as React from 'react'
import * as classnames from 'classnames'
import { Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import * as styles from './toggle-button-group.css'

type Option = {
  value: string
  label: string
  disabled?: boolean
  iconName?: string
  iconClsName?: string
  dataBi?: string
}

interface Props {
  options: Option[]
  name: string
  className?: string
  labelFilterClsName?: string
  selectedOption: string
  onSelectionChange: (id: string) => void
  ariaLabel?: string
  dataBi?: string
  labelledby?: string
  ariaDescribedby?: string
}

const ToggleButtonGroup: React.FunctionComponent<Props> = ({
  options,
  name,
  className,
  labelFilterClsName,
  selectedOption,
  onSelectionChange,
  ariaLabel,
  labelledby,
  dataBi,
  ariaDescribedby,
}) => {
  const handleChange = ({ target: { value } }) => {
    onSelectionChange(value)
  }

  return (
    <Box
      direction="row"
      className={className}
      aria-label={ariaLabel}
      role="radiogroup"
      aria-labelledby={labelledby}
      data-bi={dataBi}
    >
      {options.map(option => (
        <Box
          direction="row"
          textAlign="center"
          key={option.value}
          separator="all"
          className={styles.togButton}
          data-bi={option.dataBi || `${name}-${option.value.toLowerCase()}`}
          role="dialog"
          aria-modal="true"
          aria-label={option.dataBi || `${name}-${option.value.toLowerCase()}`}
        >
          <input
            id={option.value}
            disabled={option.disabled}
            type="radio"
            name={name}
            value={option.value}
            checked={selectedOption === option.value}
            aria-checked={selectedOption === option.value}
            onChange={handleChange}
            aria-describedby={ariaDescribedby}
          />
          <label htmlFor={option.value} className={classnames(styles.label, labelFilterClsName)}>
            {option.iconName && (
              <Icon
                name={option.iconName}
                className={classnames(styles.icon, option.iconClsName)}
              />
            )}
            {option.label}
          </label>
        </Box>
      ))}
    </Box>
  )
}

ToggleButtonGroup.displayName = 'ToggleButtonGroup'

export default ToggleButtonGroup
